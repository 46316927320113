<template>
  <div class="err">
    <img class="img-bg-error" :src='errDetail.imgsrc' alt="">
    <p class="err-text">{{errDetail.text}}</p>
  </div>
</template>

<script>
  export default {
    name: 'err',
    data () {
      return {
        errDetail:{
          imgsrc:"",
          text:""
        }
      }
    },
    props:{
      errStatus:String
    },
    created() {
      let err= this.errStatus;
      switch (err) {
        case "networkErr":
          this.errDetail={
            imgsrc:require("@/assets/images/default_image_brokennetwork.png"),
            text:"世上最遥远的距离就是没网"
          };
          break;
        case "resourceErr01":
          this.errDetail={
            imgsrc:require("@/assets/images/default_image_pagemiss.png"),
            text:"链接已失效"
          };
          break;
        case "resourceErr02":
          this.errDetail={
            imgsrc:require("@/assets/images/default_image_pagemiss.png"),
            text:"资源不存在"
          };
          break;
        case "serverErr":
          this.errDetail={
            imgsrc:require("@/assets/images/default_image_wrong.png"),
            text:"似乎出了一点小差池"
          };
          break;

      }



    },
    mounted: function() {
      document.getElementsByTagName("body")[0].className="err-body";
      document.getElementsByTagName("html")[0].className="err-body";
    },
    methods: {}

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .err-body{
    height:100%;
    background-color: #fff;
    text-align: center;
  }
  .img-bg-error{
    margin-top:8.4rem;
    width:5.88rem;
  }
  .err-text{
    font-family: PingFangSC-Medium;
    font-size:.6rem;
    margin-top:.84rem;
    color:#cfcec4;
  }
</style>
