<template>
  <div>
    <div class="showVideo" v-if="showVideoComtent">
      <div class="video-title">
        {{mainTitle}}
      </div>
      <div class="video-time">
        {{createTime|formatDate}}
      </div>
        <div class="video-div">
          <video controls="" :src="videoUrl"  name="media" :poster="coverUrl">
          </video>
        </div>
      <div class="video-detail">{{content}}</div>
    </div>
    <err v-if="showErrPage" :errStatus="errStatus"></err>
  </div>

</template>

<script>
  import err from '@/components/err'
  export default {
    data () {
      return {
        createTime:"",
        mainTitle:"",
        content:"",
        videoUrl:"",
        coverUrl:"",//预览图
        showVideoComtent:false,
        //错误页面
        showErrPage:false,
        errStatus:""
      }
    },
    components:{
      err
    },
    filters: {
      formatDate(v) {
        if(v){
          var dateObj = new Date(v);

          var month = dateObj.getMonth() + 1;
          var day = dateObj.getDate();
          var hours = dateObj.getHours();
          var minutes = dateObj.getMinutes();
          var seconds = dateObj.getSeconds();
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }
          if (hours < 10) {
            hours = "0" + hours;
          }
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          var UnixTimeToDate = dateObj.getFullYear() + '/' + month + '/' + day + ' ' + hours + ':' + minutes;
          return UnixTimeToDate;
        }else{
          return "";
        }

      }
    },
    created(){
      this.getVideoInfo();
    },
    methods: {
      async getVideoInfo() {
        const { token: Authorization, eid } = this.$route.query
        if((this.$route.query.videoId)||this.$route.query.scode){
          let params,res;
          if(this.$route.query.scode){
            //根据预览链接的scode获取文章详情
            params = {
              scode:this.$route.query.scode
            }
            res = await this.$api.cj.getResourceDetail(params, { Authorization, eid });
          }else {
            params = {videoId: this.$route.query.videoId}
            res = await this.$api.cj.getVideoInfo(params, { Authorization, eid });
          }
          if (res.retCode == 200) {
            this.mainTitle = res.data.mainTitle;
            this.createTime = res.data.createTime;
            this.content = res.data.content.replace(/static.tfcaijing.com/g,'zebra.tfcaijing.com');
            this.videoUrl = res.data.videoUrl;
            this.coverUrl = this.$imgUrl+res.data.coverUrl;
            this.showVideoComtent=true;
          }else if(res.retCode==400406){
            //链接已失效
            this.errStatus='resourceErr01';
            this.showErrPage=true;

          }else if(res.retCode==400404){
            this.errStatus='resourceErr02';
            this.showErrPage=true;
          }else{
            this.errStatus='serverErr';
            this.showErrPage=true;
          }
        }

      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.showVideo{
  width: 100%;
  min-height: 100vh;
  background: #333333;
}
  .video-title{
    font-size: 0.9rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.25rem;
    box-sizing: border-box;
    padding: 0.25rem 0.95rem;
  }
  .video-time{
    box-sizing: border-box;
    padding: 0 0.95rem 0.75rem 0.95rem;
    font-size: 0.6rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.85rem;

  }
  .time{
    font-size: 0.48rem;
    letter-spacing: 0.05rem;
    color: #333333;
  }

  .video-div{
    width: 100%;
    background-color: #000;
  }
  .video-div video{
    width:100%;
    height:100%;
    display: inline-block;
    vertical-align: baseline;
  }
  .video-detail{
    width: 100%;
    box-sizing: border-box;
    padding: 0.75rem 0.95rem;
font-size: 0.65rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
line-height: 0.9rem;
  }
</style>
